import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormField, MatFormFieldModule, MatLabel} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatOption, MatSelectModule} from '@angular/material/select';
import {MatButton} from '@angular/material/button';
import {CardBoxComponent} from './card-box/card-box.component';
import {MatIcon} from '@angular/material/icon';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion';
import {PlayersListCheckboxComponent} from './players-list-checkbox/players-list-checkbox.component';
import {MatCard, MatCardContent} from '@angular/material/card';
import {MatCheckbox} from '@angular/material/checkbox';
import {AvatarComponent} from './avatar/avatar.component';


@NgModule({
  declarations: [CardBoxComponent, PlayersListCheckboxComponent, AvatarComponent],
  imports: [
    MatFormField,
    ReactiveFormsModule,
    CommonModule,
    MatLabel,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOption,
    MatButton,
    MatIcon,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatAccordion,
    MatCard,
    MatCardContent,
    MatCheckbox,
  ],
  exports: [
    ReactiveFormsModule,
    MatFormField,
    CommonModule,
    MatLabel,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOption,
    MatButton,
    CardBoxComponent,
    MatIcon,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatAccordion,
    PlayersListCheckboxComponent,
    AvatarComponent
  ]
})
export class SharedModule { }
