@if (type === 'square') {
  <div class="box-shadow square-box">
    <div class="square-icon">
      <span class="material-symbols-outlined">{{srcImage}}</span>
    </div>
    <div class="square-content">
      <ng-content select="[slot=label]"></ng-content>
      <ng-content select="[slot=msg]"></ng-content>
    </div>
    <div class="square-btn">
      <button mat-button (click)="onClick()">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>
} @else {
  <div class="box-shadow">

  </div>
}



