import {Injectable, signal} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Player, UserInfo, UserRole} from '../../model/user';
import {Club} from '../../model/club';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userState = signal<UserInfo>(this.loadFromStorage());

  private loadFromStorage(): UserInfo {
    const userJson = localStorage.getItem('userInfo');
    return userJson ? JSON.parse(userJson) : {};
  }

  setUserState(userInfo: UserInfo) {
    this.userState.set(userInfo);
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  setUserId(id: number) {
    this.userState.update((currentUserInfo) => {
      const updatedUserInfo = {...currentUserInfo, id: id};
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
      return updatedUserInfo;
    });
  }

  setClubs(clubs: Club[]) {
    this.userState.update((currentUserInfo) => {
      const updatedUserInfo = {...currentUserInfo, clubs: clubs};
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
      return updatedUserInfo;
    });
  }

  getUserSignal() {
    return this.userState;
  }

  getUser(): UserInfo {
    return this.userState();
  }

  constructor(private http: HttpClient) {
  }

  info(id: number): Observable<UserInfo> {
    return this.http.get<UserInfo>(environment.apiUrl + '/v1/user/' + id);
  }

  roles(id: number): Observable<UserRole> {
    return this.http.get<UserRole>(environment.apiUrl + '/v1/user/' + id + '/roles');
  }

  playerAdd(request: Player): Observable<any> {
    return this.http.post<Observable<any>>(environment.apiUrl + '/v1/player', request);
  }
}
