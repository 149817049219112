<div>
  <mat-card class="checkbox-list-card" *ngFor="let option of players">
    <mat-card-content class="checkbox-item-content">
      <mat-icon class="user-icon">person</mat-icon>
      <div class="checkbox-item-details">
        <div class="surname">{{ option.surname }}</div>
        <div class="name">{{ option.name }}</div>
      </div>
      <mat-checkbox
        class="checkbox"
        [id]="option.id+''"
        [checked]="selectedOptions.has(option.id)"
        (change)="onCheckboxChange(option.id, $event.checked)"
      ></mat-checkbox>
    </mat-card-content>
  </mat-card>
</div>
