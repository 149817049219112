import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ClubCategories, PlayerCount} from '../../model/club';
import {Player} from '../../model/user';
import {TrainingCount} from '../../model/season';

@Injectable({
  providedIn: 'root'
})
export class ClubService {

  constructor(private http: HttpClient) {
  }

  create(data: any): Observable<HttpResponse<any>> {
    return this.http.post(environment.apiUrl + '/v1/club', data, {observe: 'response'});
  }

  playerCount(id: number): Observable<PlayerCount> {
    return this.http.get<PlayerCount>(environment.apiUrl + '/v1/club/player/count?cid=' + id);
  }

  players(id: number): Observable<Player[]> {
    return this.http.get<Player[]>(environment.apiUrl + '/v1/club/players?cid=' + id);
  }

  categories(): Observable<ClubCategories> {
    return this.http.get<ClubCategories>(environment.apiUrl + '/v1/categories/club');
  }

  trainingsBySeason(clubId: number, seasonId: number): Observable<TrainingCount[]> {
    return this.http.get<TrainingCount[]>(environment.apiUrl + `/v1/club/${clubId}/season/${seasonId}/trainings`);
  }

}
