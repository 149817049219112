import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Player} from '../../model/user';

@Component({
  selector: 'app-players-list-checkbox',
  templateUrl: './players-list-checkbox.component.html',
  styleUrl: './players-list-checkbox.component.scss'
})
export class PlayersListCheckboxComponent {

  @Input() players!: Player[];
  selectedOptions: Set<number> = new Set();
  @Output() selectedChange = new EventEmitter<Set<number>>();

  onCheckboxChange(optionId: number, isChecked: boolean): void {
    if (isChecked) {
      this.selectedOptions.add(optionId);
    } else {
      this.selectedOptions.delete(optionId);
    }
    this.selectedChange.emit(this.selectedOptions);
  }
}
