import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-card-box',
  standalone: false,
  templateUrl: './card-box.component.html',
  styleUrl: './card-box.component.scss'
})
export class CardBoxComponent {

  @Input() type!: string;
  @Input() action!: string;
  @Input() srcImage!: string;
  @Output() onEmit = new EventEmitter<string>();

  constructor() { }

  onClick() {
    this.onEmit.emit(this.action);
  }
}
