import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss'
})
export class AvatarComponent implements OnInit {
  @Input() name: string = '';
  @Input() surname: string = '';
  backgroundColor: string = '';

  ngOnInit() {
    this.backgroundColor = this.generateColor(`${this.name} ${this.surname}`);
  }

  generateColor(value: string): string {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = value?.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = ((hash & 0xFFFFFF) + 0xFFFFFF + 1).toString(16).slice(-6);
    if (parseInt(color, 16) < 0x444444 || parseInt(color, 16) > 0xEEEEEE) {
      color = (parseInt(color, 16) ^ 0x999999).toString(16).slice(-6);
    }
    return `#${color}`;
  }
}
